.loading-state {
	width: 100vw;
	height: 100vh;
	position: relative;
	
	> .text {
		position: absolute;
		top: 50%;
		left: 50%;
		.hide-text;
		width: 16px;
		height: 16px;
		color: @brand-blue;
		cursor: default;
		pointer-events: none;
		margin: 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		    width: 44px;
		    height: 44px;
			margin-top: -22px;
			margin-left: -22px;
		    border: 3px solid currentColor;
		    border-left-color: transparent;
		    border-radius: 50%;
			animation: loading 0.6s infinite linear;
		}
	}

	&.-reversed {
		> .text {
			color: white;
		}
	}
}

@keyframes loading {
	0 {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}