.project-sidebar {
	@media @vp-sm-down {
		background: @brand-blue-light;
		order: 2;
		margin-left: -(@page-gutters-sm);
		margin-right: -(@page-gutters-sm);
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
		
		> .media-element {
			margin-left: -(@page-gutters-sm);
			margin-right: -(@page-gutters-sm);
		}
	}
	
	@media @vp-md-up {
		position: fixed;
		top: 90px;
		left: 0;
		bottom: 0;
		.cols(3);
		background: @brand-blue-light;
		z-index: 1;
		overflow-y: scroll;
	}
	
	&.mobile-hide {
		@media @vp-sm-down {
			overflow-y: scroll;
			opacity: 0;
			position: fixed;
			left: 0;
			right: 0;
			top: 84px;
			bottom: 0;
			visibility: hidden;
			transition: visibility 0s, opacity 0.2s ease-out;
			z-index: @zindex-site-mobile-menu;
			
			&.menu-open {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	> .media-element {
		> .image {
			> .aspect {
					padding-top: percentage((3 / 4));
					background-image: url("../img/icons/tile/camera.svg");
					background-position: 50% 50%;
					background-size: 48px 48px;
					background-repeat: no-repeat;
		
					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
		
					&:after {
						content: "";
						display: block;
						width: 16px;
						height: 16px;
						position: absolute;
						bottom: @grid-col-gutter;;
						right: @grid-col-gutter;;
						background-image: url("../img/icons/white/zoom.svg");
						background-repeat: no-repeat;
						background-size: 100% 100%;
					}
				}
		}
	}
	
	> .content {
		padding-top: 1rem;
		padding-bottom: 1.5rem;
		.col-gutters;

		@media @vp-sm-down {
			margin-left: @page-gutters-sm;
			margin-right: @page-gutters-sm;
			padding-left: 0;
			padding-right: 0;
		}
		
		> .button-group {
			margin-bottom: 1.5rem;
			flex-wrap: wrap;
			margin-left: -@grid-col-gutter/2;
			margin-right: -@grid-col-gutter/2;

			> .button-link {
				flex-grow: 1;
				flex-shrink: 0;
				justify-content: space-between;
				margin-left: @grid-col-gutter/2;
				margin-right: @grid-col-gutter/2;
				margin-top: 0.5rem;
				
				.extended {
					@media @vp-md-down {
						display: none;
					}
				}
			}
		}
		
		> .section {
			margin-top: 1rem;
			
			> .title-sub {
				color: @brand-blue;
				margin-bottom: 0;
			}
			
			> p {
				.font-size-body-small;
				line-height: (20 / 14);
				
				> .item {
					display: block;
					margin-top: 4px;
					
					&.-title {
						strong { .fw-bold; }
					}
				}
				
				strong { .fw-medium; }
				
				br { display: none; }
				
				abbr {
					text-transform: uppercase;
					.font-size(12);
					.fw-medium;
					// letter-spacing: unit((1 /  10), em);
				}
			}
		}
	}
}

@media (orientation: portrait) and @vp-sm-down {
	html[onsflag-iphonex-portrait] {
		.project-sidebar  {
			top: 124px;
		}
	}
}