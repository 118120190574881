.calculations-table {
	.col-gutters;
	
	> .table {
		width: 100%;

		thead > tr > th {
			.spaced-uppercase(10);
			color: @body-secondary;
			border-bottom: 1px solid @brand-blue-light;
			padding: 0 0.5rem 0.5rem 0;
		
			&:last-child {
				padding-right: 0;
				text-align: right;
			}
		}
	
		tbody > tr {
			td, th {
				border-bottom: 1px solid @brand-blue-light;
				padding: 0.5rem 0.5rem 0.5rem 0;
		
				&:last-child {
					padding-right: 0;
					text-align: right;
				}
				
				> p {
					margin-bottom: 0;
				}
				
				> .warning {
					color: @brand-secondary;
					margin-bottom: 0;
					text-align: left;
					.font-size-body-small;
				}
			}
		
			th {
				.fw-regular;
			}
			
			&:not(.total) {
				+ .total {
					th, td {
						padding-top: 1rem;
					}
				}
			}
			
			&.subtotal,
			&.total {
				th {
					.spaced-uppercase;
				}
				td, th {
					// .fw-medium;
				}
			}
			
			&.subtotal {
				td, th {
					padding-bottom: 1.5rem;
				}
				
				+ .total {
					th, td {
						padding-top: 0.5rem;
					}
				}
			}
		}
	}
	
	+ * { margin-top: 1rem; }
}