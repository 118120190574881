.mobile-nav {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 44px;
  z-index: @zindex-site-mobile-menu;
  justify-content: space-between;
	padding-left: @page-gutters-sm;
	padding-right: @page-gutters-sm;
  background-color: @brand-blue-light;
  border-bottom: 1px solid @body-secondary;
  height: 40px;

  @media @vp-sm-down {
    display: flex;
  }
  
  > .main {
    width: 48px;
    border-left: 1px solid @body-secondary;
    border-right: 1px solid @body-secondary;
    transition: background-color 0.25s ease-in-out;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }
  
  > .prev, > .next {
    line-height: 40px;
    opacity: 1;
    transition: color 0.25s ease-in-out;
    transition+: opacity 0.25s ease-in-out;
    
    &:hover {
      color: darken(@brand-blue, 20%);
      cursor: pointer;
    }
  }
  
  > .prev {
    &:before {
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../img/icons/blue/arrow.svg");
      transform: rotate(180deg);
      margin-bottom: -1px;
      margin-right: 5px;
    }

    &.-empty {
      &:before {
        background: none;
      }
    }
  }
  
  > .next {
    &:after {
      content: '';
      display: inline-block;
      height: 12px;
      width: 12px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../img/icons/blue/arrow.svg");
      margin-bottom: -1px;
      margin-left: 5px;
    }

    &.-empty {
      &:after {
        background: none;
      }
    }
  }

  &.menu-open {
    > .prev, > .next {
      opacity: 0;
    }
    
    > .main {
      border-left: none;
      border-right: none;
    }
  }
}


@media (orientation: portrait) {
	html[onsflag-iphonex-portrait] {
		.mobile-nav {
			top: 88px;
		}
	}
}