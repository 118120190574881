.button-group {
	display: flex;
	flex-direction: row-reverse;
	
	> .button-link {
		flex-shrink: 0;
		
		+ .button-link { margin-right: @grid-col-gutter; }
	}
	
	&.-expand {
		> .button-link {
			flex-grow: 1;
			
			&.-delete.-text {
				flex-grow: 0;
			}
		}
	}
	
	> .upload {
		overflow: hidden;
		position: relative;
		margin-bottom: 0;
		text-align: center;
		
		> .button-link {
			display: inline-block;
		}
		
		+ .button-link { margin-right: @grid-col-gutter; }
		
		> .file {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}
	}

	&.-photoactions {
		> .button-link {
			&.-photos {
				@media @vp-xs {
					.extended { display: none; }
				}
			}

			&.-camera,
			&.-share,
			&.-delete {
				@media @vp-xs {
					flex-grow: 0;
					width: 44px;
					font: ~"0/0" a;
					color: transparent;
					text-shadow: none;
					text-align: center;
					
					&:before { margin-right: 0; }
				}
			}
		}
	}
}