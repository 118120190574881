.title-sub & {
	// Remove spacing and borders in this instance
	
	+ .product-types {
		margin-top: -1rem;
		
		&:before { display: none; }
	}
}

.product-types {
	position: relative;
	.border(top);
	
	> .product {
		position: relative;
		.border(bottom);
		
		> .title-section {
			display: flex;
			margin: 0;
			padding-top: 1rem;
			padding-bottom: 1rem;
			position: relative;
			
			> .count {
				display: block;
				.cols(1, 7);
				.col-gutters;
			}
			
			> .name {
				display: block;
				.col-gutters;
			}
		}
		
		> .content {
			padding-top: 0.5rem;
			padding-bottom: 1.5rem;
			.col-gutters;
			display: none;
			
			> .table {
				width: 100%;
				
				thead {
					@media @vp-md-down { display: none; }
					
					> tr > th {
						.spaced-uppercase(10);
						color: @body-secondary;
						border-bottom: 1px solid @brand-blue-light;
						padding: 0 0.5rem 0.5rem 0;

						&:last-child {
							padding-right: 0;
							text-align: right;
						}
					}
				}
				
				tbody > tr {
					@media @vp-md-down {
						display: flex;
						flex-wrap: wrap;
						border-bottom: 1px solid @brand-blue-light;
						padding: 0.5rem 0;
						
						&:first-child { padding-top: 0; }
					}

					&.-deleted {
						td, th {
							text-decoration: line-through;
						}
						td.actions {
							text-decoration: none;
						}
					}
					
					td, th {
						@media @vp-md-down {
							display: flex;
							width: 100%;
							padding-bottom: 0;
							padding-top: 0;
							
							&.name {
								order: 1;
								width: auto;
								flex-grow: 1;
							}
							&.actions {
								order: 2;
								margin-left: auto;
								width: 48px;
								flex-grow: 0;
								justify-content: space-between;
								padding-top: unit((2 / @rem-base), rem);

								&.-haswarning {
									width: 80px;
								}
							}
							&.type { order: 3; }
							&.window { order: 4; }
							&.fabric { order: 5; }
							&.quantity { order: 6; }
						}
						
						@media @vp-lg-up {
							border-bottom: 1px solid @brand-blue-light;
							padding: 0.5rem 0.5rem 0.5rem 0;
					
							&:last-child {
								padding-right: 0;
								text-align: right;
							}
						}
						
						> .label {
							margin: 0;
							
							@media @vp-lg-up {
								display: none;
							}
							
							@media @vp-md-down {
								.cols(3, 7);
								flex-shrink: 0;
								flex-grow: 0;
								.spaced-uppercase(10);
								color: @body-secondary;
								line-height: 16 / 10;
								padding-top: unit((3 / 10), em);
							}
						}
						
						> .data { margin: 0; }
						
						&.actions {
							white-space: nowrap;
							
							@media @vp-lg-up {
								> .icon + .icon { margin-left: 1rem; }
							}
						}
					
						> .icon {
							.hide-text;
							display: inline-block;
							vertical-align: middle;
							width: 16px;
							height: 16px;
							background-repeat: no-repeat;
							background-size: 100% 100%;
							opacity: 0.8;
							
							+ .icon { margin-left: @grid-col-gutter * 2; }
							
							&.-edit {
								background-image: url("../img/icons/blue/edit.svg");
							}
							
							&.-delete {
								background-image: url("../img/icons/blue/delete.svg");
							}

							&.-warning {
								background-image: url("../img/icons/warning.svg");
								opacity: 1;
							}
						}
					}
					
					td {
						@media @vp-md-down {
							flex-grow: 1;
							padding-bottom: 0.25rem;
							
							// &.actions {
							// 	flex-grow: 0;
							// 	padding-top: 0.5rem;
							// 	margin-left: percentage((3 / 7));
							// }
						}
					}
					
					th {
						.fw-medium;

						@media @vp-md-down {
							width: 100%;
							border-bottom: none;
							padding-bottom: 0.5rem;
						}
					}
				}
				
				+ * { margin-top: 1rem; }
			}
			
			> .button-link {
				margin-left: auto;
			}
		}
		
		&.-open {
			> .title-section {
				&:after {
					transform: rotate(180deg);
				}
			}
			
			> .content {
				display: flex;
				flex-direction: column;
			}
		}
	}
}