.form-field {
	display: flex;
	flex-wrap: wrap;
	
	> .label {
		.font-size-label;
		// padding-top: unit((10 / 16), em);
		max-height: 44px;
		.col-gutters;
		
		@media @vp-lg-up {
			.cols(3, 7);
			align-self: center;
			margin: 0;
		}
		
		@media @vp-md-down {
			width: 100%;
		}
		
		&.-strong {
			.fw-medium;
		}
		
		> .note {
			display: inline-block;
			.font-size-label-note;
			color: @body-secondary;
		}
		
		+ .form-input.-options {
			margin-top: -0.5rem;
		}
		
		> .copy {
			display: block;
			.spaced-uppercase(11);
			.fw-regular;
			color: @brand-grey-light;
			margin-top: 0.25rem;
			padding-left: 1rem;
			
			&:before {
				content: "";
				display: inline-block;
				vertical-align: text-top;
				margin-right: 5px;
				width: 12px;
				height: 12px;
				background-image: url("../img/icons/copy.svg");
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 0 0;
				opacity: 0.8;
			}
			
			&:hover {
				color: @brand-grey;
			}
			
			&:active {
				color: @brand-grey;
				
				&:before { opacity: 1; }
			}
		}
	}
	
	> .break { display: none; }
	
	&.-topalign {
		> .label {
			align-self: flex-start;
		}
	}

	> .form-input,
	> .button-group {
		.col-gutters;
		
		@media @vp-md-down {
			width: 100%;
		}
		
		@media @vp-lg-up {
			.cols(4, 7);
		}
	}
	
	> .form-input {
		> .media-element {
			> .image {
				background: @brand-blue-xlight;
				outline: 1px solid mix(@brand-blue, @brand-blue-xlight, 25%);
				
				img {
					display: block;
					max-width: 100%;
					height: auto;
					max-height: 400px;
					margin-left: auto;
					margin-right: auto;
					
					@media (max-height: 768px) {
						max-height: 300px;
					}
				}
			}
			
			+ .button-group { margin-top: 1rem; }
		}
	}
	
	> .button-group {
		> .button-link {
			margin-top: unit((5 / @rem-base), rem);
			margin-bottom: unit((5 / @rem-base), rem);
		}
	}
	
	> .note,
	> .form-input .note {
		width: 100%;
		margin-top: 0.5rem;
		margin-bottom: 0.25rem;
		.font-size-label-note;
		color: @body-secondary;
	}
	
	> .note {
		@media @vp-md-down {
			.col-gutters;
		}
	}
	
	.form-field > .note {
		@media @vp-md-down {
			.col-gutters-reset;
		}
	}
	
	&.-grouped {
		margin-bottom: 0.5rem;
		
		> .label {
			align-self: flex-start;
			
			@media @vp-md-down {
				.cols(3, 7);
				align-self: center;
				margin-top: unit(((16 + @rem-base) / @rem-base), rem);
			}

			@media @vp-sm-down {
				.cols(2.5, 7);
			}
		}

		> .group {
			display: flex;
			.cols(4, 7);

			@media @vp-sm-down {
				.cols(4.5, 7);
			}

			&.-full-width {
				width: 100%;
			}
			
			> .form-input {
				.col-gutters;
				display: flex;
				flex-wrap: wrap;
				width: 50%;
				align-self: flex-end; 
				
				> .label {
					.spaced-uppercase(11);
					.fw-medium;
					width: 100%;
					margin-bottom: 0.25rem;
				}
			}
			
			> .joiner {
				width: 32px;
				flex-shrink: 0;
				flex-grow: 0;
				align-self: flex-start;
				margin-top: unit(((10 + @rem-base) / @rem-base), rem);

				@media @vp-sm-down {
					width: 20px;
					align-self: flex-end;
					margin-bottom: 12px;
				}
				
				> .link {
					border: none;
					display: block;
					text-decoration: none;
					text-align: center;
					cursor: pointer;
					background: transparent;
					appearance: none;
					font-family: inherit;
					width: 32px;
					height: 32px;
					border: 2px solid @brand-blue-light;
					border-radius: 50%;
					position: relative;
					overflow: hidden;
					white-space: nowrap;
					text-align: left;
					text-indent: 200%;
					font-size: 0px;
					margin-left: auto;
					margin-right: auto;
					background-image: url("../img/icons/link.svg");
					background-repeat: no-repeat;
					background-size: 16px 16px;
					background-position: 50% 50%;
					opacity: 0.5;

					@media @vp-sm-down {
						width: 20px;
						height: 20px;
					}
					
					&:hover {
						border-color: mix(@brand-blue, @brand-blue-light, 35%);
						opacity: 0.75;
					}
					
					&.-linked {
						background-color: @brand-blue-light;
						border: 1px solid mix(@brand-blue, @brand-blue-light, 50%);
						opacity: 1;
					}
				}
			}
			
			>  .units {
				width: 20%;
				display: block;
				.col-gutters;
				.font-size-label;
				color: @body-secondary;
				align-self: flex-start;
				margin: 0 0 8px 0;
				margin-top: unit(((16 + @rem-base) / @rem-base), rem);
				max-height: 44px;
				align-self: flex-end;

				@media @vp-sm-down {
					.font-size-label-sm;
				}
			}
		}
	}
	
	&.-wide {
		> .label {
			width: 100%;
			margin-bottom: 0.5rem;
		}
		
		> .form-input {
			width: 100%;
		}
	}
	
	&.-short {
		> .label {
			@media @vp-md-down {
				// .cols(3, 7);
				// align-self: center;
			}
		}
		
		> .form-input {
			.cols(2, 7);

			@media @vp-xs {
				width: 40%;

				> .warning {
					width: percentage((100 / 40));
				}
			}
		}
		
		>  .units {
			display: block;
			.cols(2, 7);
			.col-gutters;
			.font-size-label;
			color: @body-secondary;
			align-self: center;
			margin: 0;
			max-height: 44px;
			
			@media @vp-sm-down {
				.font-size-label-sm;
			}
		}
		
		&.-options {
			padding-top: 1rem;
			
			> .form-input {
				.cols(1.5, 7);
				min-width: 80px;

				@media @vp-xs {
					// .cols(2.5, 7);
				}
			}
			
			> .units {
				width: auto;
				padding-left: 0;
				padding-right: 0;
			}
			
			> .form-input + .units {
				margin-left: 1rem;
				
				&.-following {
					margin-left: 0;
					
					+ .units { margin-left: 1rem; }
				}
			}
			
			> .note { margin-top: 1rem; }
			
			@media @vp-sm-down {
				> .units { width: 120px; }
				
				> .break {
					display: block;
					width: 100%;
					height: 0;
					margin-bottom: 0.5rem;
					
					+ .note {
						margin-top: 0.5rem;
					}
				}
			}

			@media @vp-xs {
				> .units { width: 80px; }
				> .form-input { width: 40%; }
			}
		}
	}
	
	&.-withother {
		> .label {
			align-self: flex-start;
			margin-top: unit((10 / 16), em);
		}
		
		> .form-input {
			display: flex;
			
			>  .units {
				display: block;
				padding-left: @grid-col-gutter;
				.font-size-label;
				color: @body-secondary;
				align-self: center;
				margin: 0;
				max-height: 44px;
				white-space: nowrap;
			}
		}
	}

	&.-withbutton {
		> .label {
			align-self: flex-start;
			padding-top: 0.75rem;
		}

		> .form-input {
			display: flex;
			flex-wrap: wrap;

			> .field {
				width: 70%;

				@media @vp-xs {
					width: 60%;
				}
			}

			> .button-link {
				white-space: nowrap;
				width: 30%;

				@media @vp-xs {
					width: 40%;
				}
			}

			> .note {
				display: block;
				width: 100%;
			}
		}
	}
}