.project-list {
	@media @vp-md-up {
		display: flex;
		flex-wrap: wrap;
	}
	
	@media @vp-sm-down {
		margin-left: -(@page-gutters-sm);
		margin-right: -(@page-gutters-sm);
	}
	
	> .project {
		@media @vp-md-up {
			width: percentage((1 / 4));
		}
		
		@media @vp-sm-down {
			+ .project { margin-top: 0.5rem; }
		}
		
		> .media-element {
			.col-gutters;
			
			@media @vp-sm-down {
				display: flex;
			}
			
			> .image {
				@media @vp-sm-down {
					.cols(3);
					flex-shrink: 0;
					margin-right: 1rem;
				}
				
				> .aspect {
					padding-top: percentage((3 / 4));
					background-image: url("../img/icons/tile/camera.svg");
					background-position: 50% 50%;
					background-size: 48px 48px;
					background-repeat: no-repeat;
					
					> img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					
					> .add {
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 48px;
						height: 48px;
						background-image: url("../img/icons/tile/add.svg");
						background-position: 50% 50%;
						background-size: 48px 48px;
						background-repeat: no-repeat;
					}
				}
			}
			
			> .caption {
				@media @vp-md-up {
					text-align: center;
					padding-top: 0.5rem;
					padding-bottom: 1.5rem;
				}
				
				@media @vp-sm-down {
					align-self: center;
				}
				
				> .name {
					margin: 0;
					font-size: 1rem;
					line-height: (18 / 16);
					.fw-medium;
					
					+ .metadata {
						margin-top: 0.25rem;
					}
				}
				
				> .metadata {
					.font-size-metadata;
					color: @body-secondary;
					margin: 0;
				}
			}
		}
		
		&.-add {
			> .media-element > .image > .aspect { background-image: none; }
		}
	}
	
	&.-editing {
		> .project > .media-element > .image > .aspect > .edit-tile {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}