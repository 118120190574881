@font-face{
	font-family:"Work Sans Regular";
	src: url("../fonts/WorkSans-Regular.ttf") format("truetype");
}
@font-face{
	font-family:"Work Sans Medium";
	src: url("../fonts/WorkSans-Medium.ttf") format("truetype");
}
@font-face{
	font-family:"Work Sans Semibold";
	src: url("../fonts/WorkSans-SemiBold.ttf") format("truetype");
}
@font-face{
	font-family:"Montserrat Regular";
	src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face{
	font-family:"Montserrat Medium";
	src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
}