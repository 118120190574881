/* Variables & Helpers
------------------------------------------------------------------------------*/
@import "core/constants";
@import "core/mixins";
@import "core/helpers";

/* Vendor
------------------------------------------------------------------------------*/
@import (less) "../vendor/normalize.css/normalize.css";
// @import (less) "../vendor/flickity/flickity.css";

/* Core
------------------------------------------------------------------------------*/
@import "core/fonts";
@import "core/scaffolding";

/* Components
------------------------------------------------------------------------------*/
// @import "components/type";
@import "components/annotation-toolbar";
@import "components/banner-message";
@import "components/body-text";
@import "components/burger";
@import "components/button-link";
@import "components/button-group";
@import "components/calculations-table";
@import "components/copy-form";
@import "components/debug";
@import "components/edit-tile";
@import "components/form-field";
@import "components/form-input";
@import "components/form-layout";
@import "components/loading-state";
@import "components/media-element";
@import "components/message-bar";
@import "components/mobile-nav";
@import "components/modal-content";
@import "components/onsen-ui";
@import "components/option-inputs";
@import "components/option-menu";
@import "components/product-types";
@import "components/project-body";
@import "components/project-costs";
@import "components/project-list";
@import "components/project-nav";
@import "components/project-sidebar";
// @import "components/site-footer";
@import "components/signin-form";
@import "components/side-nav";
@import "components/site-body";
@import "components/site-header";
@import "components/site-logo";
@import "components/site-splash";
@import "components/toggle-selector";
// @import "components/site-nav";
@import "components/type";

/* Onsenui generated files */

@import "onsen-css-theme/onsen-css-components.css";

/* Onsen overrides
------------------------------------------------------------------------------*/
@import "components/page";


/* Screens
------------------------------------------------------------------------------*/
@import "screens/screen-editproject";
@import "screens/screen-project";

body {
	background: white;
}

html, body {
	height: 100%;
	overflow: hidden;
}

.site-header,
.site-body,
.site-footer,
.width-limit.-pageboundaries {
	padding-left: @page-gutters;
	padding-right: @page-gutters;
	
	@media @vp-md-and-lg {
		padding-left: @page-gutters-md;
		padding-right: @page-gutters-md;
	}
	
	@media @vp-sm-down {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
	}
}

.width-limit {
	box-sizing: border-box;

	// &.-site {
	// 	max-width: @constraint-site;
	// 	margin-left: auto;
	// 	margin-right: auto;
	// }
	
	&.-pageboundaries {
		box-sizing: content-box;
		
		> * { box-sizing: border-box; }
	}
}

// For focus-visible polyfill — https://github.com/WICG/focus-visible
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}