.site-splash {
	display: block;
	background: @brand-blue;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	
	> .site-logo {
		
	}
}