.option-inputs {
	margin: 0;
	// margin-top: -0.5rem;
	padding-left: 0;
	margin-bottom: 0.5rem;
	
	> .option {
		list-style: none;
		margin-top: 0.5rem;
		
		@media @vp-sm-down {
			.font-size-body-small;
		}
		
		> .label {
			margin-bottom: 0;
			color: @brand-blue;
		}
		
		&.-radio {
			> .label {
				.option-input();
				
				@media @vp-sm-down { padding-left: 28px; }
			}
		}

		&.-checkbox {
			> .label {
				.option-input();
				
				@media @vp-sm-down { padding-left: 28px; }
			}
		}
	}
	
	&.-inline {
		> .option {
			display: inline-block;
			margin-right: 1.5em;
		}
	}
	
	&.-single {
		> .option {
			// margin-top: 0;
		}
	}
}