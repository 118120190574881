.button-link {
	border: none;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	text-align: center;
	cursor: pointer;
	background: transparent;
	appearance: none;
	font-family: inherit;
	.button-size-default;
	border: none;
	border-radius: 2px;
	position: relative;
	overflow: hidden;
	
	&.-action {
		background: @brand-secondary;
		color: white !important;
		
		&:active {
			background: darken(@brand-secondary, 10%);
			color: white;
		}
	}
	
	&.-reversed {
		background: white;
		color: @brand-blue;
		
		&:hover { color: currentColor; }
	}

	&.-icon,
	&.-icon:active {
		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: -1px;
			height: 16px;
			width: 16px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 0.5rem;
		}
		
		&.-edit {
			&:before {
				background-image: url("../img/icons/blue/edit.svg");
				opacity: 0.8;
			}
		}

		&.-new {
			&:before { background-image: url("../img/icons/white/add.svg"); }
		}

		&.-cancel {
			white-space: nowrap;

			@media @vp-xs {
				padding: 0;
				margin-left: auto;
				margin-top: 10px;
				margin-bottom: 10px;
			}

			&:before {
				background-image: url("../img/icons/blue/cancel.svg");
				opacity: 0.8;
			}
		}

		&.-delete {
			&.-text {
				&:before {
					background-image: url("../img/icons/red/delete.svg");
					opacity: 0.8;
				}
			}
		}

		&.-quote {
			&:before {
				background-image: url("../img/icons/white/quote.svg");
				opacity: 0.8;
			}
		}
		
		&.-camera {
			&:before {
				width: 20px;
				height: 20px;
				background-image: url("../img/icons/white/camera-large.svg");
				opacity: 0.8;
			}
		}

		&.-photos {
			&:before {
				width: 20px;
				height: 20px;
				background-image: url("../img/icons/white/photos-large.svg");
				opacity: 0.8;
			}
		}

		&.-share {
			&:before {
				width: 20px;
				height: 20px;
				background-image: url("../img/icons/white/share-large.svg");
				opacity: 0.8;
			}
		}

		&.-annotate {
			&:before {
				width: 20px;
				height: 20px;
				background-image: url("../img/icons/white/annotate-large.svg");
				opacity: 0.8;
			}
		}
	}
	
	&:hover {
		color: white;
		border-color: @brand-blue;
		
		&:before {
			transform: scaleX(1);
			transition: all 0.3s @easeOutQuart;
		}
		
		> .label {
			color: white;
			transition: all 0.2s @easeOutQuart;
		}
		
		&[disabled] {  }
	}
	
	&:active {
		border-color: @brand-black;
		background: @brand-black;
		transition: none;
		
		&:before {
			display: none;
			transition: none;
		}
		
		> .label {
			color: white;
			transition: none;
		}
		
		&[disabled] {  }
	}
	
	&.-secondary {
		background: @brand-blue;
		color: white;
	}

	&.-dark {
		color: white;
		background: @brand-black;
		border-color: @brand-black;
		
		&:hover {
			color: white;
			border-color: @brand-blue;
		}
		
		&:active {
			border-color: @brand-blue;
			background: @brand-blue;
		}
	}
	
	&.-text {
		background: none;
		border: none;
		color: @brand-blue;
		.button-size-text;
		
		&.-warning {
			color: @brand-secondary;
		}
	}
	
	&.-more {
		&:after {
			content: "";
			display: inline-block;
			.arrow-graphic;
			width: 25px;
			margin-left: 0.25rem;
			position: relative;
			top: -1px;
		}
		
		&:hover {
			&:after { background-position: right -40px; }
		}
	}

	&.-hero {
		.button-size-hero;
	}
	
	&.-small {
		.button-size-small;
		
		&.-icon {
			&.-notext {
				flex-grow: 0;
				width: 44px;
			    font: ~"0/0" a;
			    color: transparent;
			    text-shadow: none;
				text-align: center;
				
				&:before { margin-right: 0; }
			}
		}
	}
	
	&.-xsmall {
		.button-size-xsmall;
	}
	
	&.-micro {
		.button-size-micro;
	}
	
	&.-fullwidth {
		width: 100%;
	}
	
	&.-disabled,
	&[disabled] {
		opacity: 0.35;
		cursor: default;
	}
}