.message-bar {
  position: fixed;
  z-index: @zindex-site-pending-message;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @change-pending-background;
  border-top: 1px @change-pending-border solid;
  padding-left: @page-gutters;
  padding-right: @page-gutters;
  display: flex;
  align-items: center;
  
  p {
    color: @change-pending-text;
    margin-bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
	
	@media @vp-sm-down {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
  }
  
  &.-spacer {
    position: relative;
    visibility: hidden;
  }
}
