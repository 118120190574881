.option-menu {
	position: relative;
	.col-gutters;
	
	> .title {
		margin: 0;
		
		> .selected {
			display: inline-block;
			.fw-medium;
			border-bottom: 1px solid fade(@brand-blue, 50%);
			padding-right: 0.75rem;
			position: relative;
			cursor: pointer;
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				right: 0;
				top: 50%;
				width: 0;
				height: 0;
				margin-top: -2px;
				border: 4px solid transparent;
				border-top-color: @brand-blue;
				border-bottom-width: 0;
			}
		}
	}
	
	&.-reversed {
		> .title > .selected {
			&:after {
				border-top-color: white;
			}
		}
	}
	
	> .options {
		position: absolute;
		right: -0.25rem;
		background: white;
		border-radius: 4px;
		z-index: 1;
		box-shadow: 0 2px 10px fade(black, 35%);
		list-style: none;
		padding: 0.5rem 0;
		display: none;
		
		li {
			> .label {
				display: block;
				padding: 0.25rem 1rem;
				color: @body-secondary;
				white-space: nowrap;
			}
			
			> a.label {
				color: @body;
				cursor: pointer;
			}
		}
		
		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			right: 7px;
			bottom: 100%;
			width: 0;
			height: 0;
			
		}

		&:before {
			border: 9px solid transparent;
			border-top-width: 0;
			border-bottom-color: fade(black, 10%);
		}

		&:after {
			border: 8px solid transparent;
			border-top-width: 0;
			border-bottom-color: white;
			right: 8px;
		}
	}
	
	&.-active {
		> .options { display: block; }
		> .title {
			> .selected { border-color: transparent; }
		}
	}	
}