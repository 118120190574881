.title-page {
	.font-size-page-title;
	margin: 0;

	// ONSEN
	.page__content & {
		.font-size-page-title;
		margin: 0;
	}
}

.title-section {
	.font-size-sectiontitle;
	margin: 0;
	margin-bottom: 3rem;
	
	// ONSEN
	.page__content & { .font-size-sectiontitle; }
}

.title-sub { // ONSEN
	.font-size-subtitle;
	margin: 0;
	margin-bottom: 1rem;
	color: @brand-secondary;
	
	// ONSEN
	.page__content & { .font-size-subtitle; }
	
	&.-underlined {
		border-bottom: 1px solid @brand-black;
		padding-bottom: 0.5rem;
		margin-bottom: 2rem;
	}
}

.title-small {
	.font-size-smalltitle;
	margin: 0;
	margin-bottom: 1rem;
	color: @brand-blue;

	// ONSEN
	.page__content & { .font-size-smalltitle; }
}

.subtle {
	color: #ddd;
}
