.screen-editproject {
	.site-body {
		> .width-limit {
			display: flex;
			width: 100%;

			> .project-body {
				.cols(9);
				position: relative;
				z-index: 0;
				margin-left: percentage((3 / @grid-cols-total));
			}
		}
	}
}