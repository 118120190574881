// ONSEN overrides
.page {
	.body-font;
	font-size: @fs-root;
	line-height: @lh-root;
	color: @body;
}

.page__content {
	> .site-body {
		min-height: 100%;
		display: flex;
		
		> .width-limit {
			flex-grow: 1;
		}
	}
}