.banner-message {
	width: 100%;
	background: #fe4;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	> p {
		margin: 0;
		.col-gutters;

		@media @vp-md-down {
			.font-size-body-small;
		}

		@media @vp-xs {
			.font-size-body-xsmall;
			line-height: (16 / 12);
		}
	}
}