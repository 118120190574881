@import "mixins/clearfix";
@import "mixins/border";
@import "mixins/font-face";
@import "mixins/font-size";
@import "mixins/font-smoothing";
@import "mixins/hide-text";
@import "mixins/option-input";
@import "mixins/screen-reader";
@import "mixins/svg-images";
@import "mixins/tab-focus";
@import "mixins/type";
@import "mixins/menu-hamburger";
@import "mixins/prevent-user-select";
@import "mixins/cols";
@import "mixins/col-gutters";
@import "mixins/field-defaults";
@import "mixins/button-sizes";

.col-gutters() {
	padding-left: @grid-col-gutter;
	padding-right: @grid-col-gutter;
}

.col-gutters-sm() {
	padding-left: @grid-col-gutter-sm;
	padding-right: @grid-col-gutter-sm;
}

.col-gutters-as-margin() {
	margin-left: @grid-col-gutter;
	margin-right: @grid-col-gutter;
}

.col-gutters-as-margin-sm() {
	margin-left: @grid-col-gutter-sm;
	margin-right: @grid-col-gutter-sm;
}

.cols(@cols: @grid-cols-total, @cols-total: @grid-cols-total) {
	width: percentage((@cols / @cols-total));
}

.outline(@colour: red) {
	// For help when debugging
	outline: 1px solid @colour;
}

.arrow-graphic() {
	display: inline-block;
	vertical-align: middle;
	background-image: url("../img/icons/blue/arrow.svg");
	background-position: 100% 0;
	background-size: 55px 60px;
	height: 17px;
	width: 55px;
}