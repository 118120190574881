// Creates an A/B style segmented toggle which accepts only two options

.toggle-selector {
	display: flex;

	@media @vp-xs {
		flex-wrap: wrap;
		align-items: center;
	}
	
	> .toggles {
		margin: 0;
		padding-left: 0;
		list-style: none;
		display: flex;
		
		> .toggle {
			margin-left: -2px;
			
			> .label {
				position: relative;
				line-height: (24 / 16);
				.button-size-small;
				margin: 0;
				height: 100%;
				align-items: center;
				display: flex;

				> .text {
					position: relative;
					z-index: 1;
					pointer-events: none;
				}
				
				> .radio {
					position: absolute;
					left: 0;
					z-index: 0;
					opacity: 0;
					border: none;
		
					+ .substitute {
						pointer-events: none;
						display: block;
						position: absolute;
						z-index: 0;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: white;
						border: 2px solid @brand-blue-light;
						box-sizing: border-box;
						color: @brand-blue;
					}
					
					&:checked {
						+ .substitute {
							background: @brand-blue;
							border-color: @brand-blue;
							z-index: 1;
							
							+ .text { color: white; }
						}
					}
				}
			}
			
			&:first-child {
				margin-left: 0;
				
				> .label {
					> .radio + .substitute {
						border-top-left-radius: 3px;
						border-bottom-left-radius: 3px;

					}
				}
			}

			&:last-child {
				> .label {
					> .radio + .substitute {
						border-top-right-radius: 3px;
						border-bottom-right-radius: 3px;
					}
				}
			}
		}
	}
	
	> .button-link {
		padding-left: 1.5rem;
	}
}