.project-costs {
	> .row {
		+ .row {
		    padding-top: 1.5rem;
		}
		
		> .form-field {
			> .label {
				@media @vp-md-up and @vp-lg-down {
					.cols(2, 7);
				}
					
				@media @vp-sm-down {
					.fw-medium;
					margin-bottom: 1rem;
				}
			}
			
			> .form-input {
				@media @vp-md-up and @vp-lg-down {
					.cols(5, 7);
				}
				
				> .form-field {
					&.-short.-options {
						@media @vp-sm-down {
							> .units { width: 120px; }
							
							> .break {
								display: block;
								width: 100%;
								height: 0;
								margin-bottom: 0.5rem;
								
								+ .note {
									margin-top: 0.5rem;
								}
							}
						}

						@media @vp-xs {
							> .units { width: 80px; }
							> .form-input { width: 40%; }
						}
					}
				}
			}
		}
	}
}