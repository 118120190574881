.copy-form {
	margin-bottom: 3rem;
	display: flex;
	.col-gutters;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: @brand-blue;
	background: @brand-blue-xlight;
	border-radius: 3px;
	
	@media @vp-md-down {
		flex-wrap: wrap;
	}
	
	> .title-sub {
		white-space: nowrap;
		color: @brand-blue;
		
		.page__content & {
			.font-size-body-small;
			.fw-medium-title;
			margin-top: 0;
			margin-bottom: 0;
			margin-right: 0.5rem;
			align-self: center;
			
			@media @vp-md-down {
				width: 100%;
				margin-bottom: 0.5rem;
			}
		}
	}
	
	> .form-input {
		flex-grow: 1;
	}
	
	> .button-group {
		@media @vp-md-down {
			margin-left: auto;
			margin-top: 0.5rem;
		}
		
		@media @vp-lg-up {
			flex-direction: initial;
			
			> .button-link {
				margin-left: 0.5rem;
		
				+ .button-link {
					margin-right: 0;
			
					&.-text {
						padding-right: @grid-col-gutter;
					}
				}
			}
		}
	
	}
}