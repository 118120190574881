.project-body {
	padding-top: 1.5rem;
	padding-bottom: 3.75rem;
	
	> .content {
		@media @vp-md-up {
			.cols(7, 9);
			margin-left: percentage((1 / 9));
		}
		
		@media @vp-md-up and @vp-lg-down {
			.cols(8, 9);
			margin-left: percentage((0.5 / 9));
		}
	}
	
	@media @vp-sm-down {
		order: 1;
	}

	@media @vp-md-up {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		.cols(9);
		z-index: 0;
	
		&:before {
			content: "";
			display: block;
			position: fixed;
			height: 100%;
			right: 100%;
			top: 0;
			bottom: 0;
			background: @brand-blue-light;
			width: calc(~"33.3333333% + 30px");
			transform: translateX(8px);
		}
	}
}

.mobile-nav + .project-body {
	@media @vp-sm-down {
		padding-top: 3.75rem;
	}
}