.screen-project {
	@media @vp-md-up {
		.page__content {
			overflow: initial;
			-webkit-overflow-scrolling: none;
		}

		.project-body {
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
	}
		
	.toolbar__left {
		display: flex;
		position: relative;
		align-items: flex-end;
		
		@media @vp-sm-down {
			width: auto;
		}

		@media @vp-md-up {
			.cols(3);
			height: @header-height;
		}
		
		> .back-button {
			position: relative;
			height: auto;
			.fw-medium;
			margin: 0 0 0.8rem @grid-col-gutter;
			
			@media @vp-sm-down {
				width: 24px;
				height: 44px;
				margin: 0 0 2px @page-gutters-sm;
			}
	
			.back-button__icon {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				margin-left: -14px;
				height: 44px;
				width: 44px;
				background-repeat: no-repeat;
				background-size: 16px 16px;
				background-image: url("../img/icons/white/back.svg");
				background-position: 50% 50%;
				opacity: 0.8;
			
				svg { display: none; }
			}
			
			.back-button__label {
				height: auto;
				vertical-align: middle;
				line-height: (26 / 16);
				font-size: 15px;
				color: white;
				padding-left: 14px;
				
				@media @vp-sm-down { display: none; }
			}
		}

		> .back-button.-home {
			.back-button__icon {
				background-image: url("../img/icons/white/home.svg");
			}

			.back-button__label {
				padding-left: 16px;
			}
		}
	}
	
	@media @vp-md-up {
		.toolbar {
			justify-content: space-between;
		}
	
		.toolbar__left {
			.back-button--material {
			    font-size: 16px;
			    color: white;
			    display: block;
			    padding: 0;
			    height: auto;
			    margin: 0 0 1rem @grid-col-gutter;
			    line-height: (26 / 16);
				min-height: 26px;
			}
			
			> .contents {
				display: flex;
				align-items: flex-end;
				height: @header-height;
				.col-gutters;
				
				> .back {
					line-height: (26 / 16);
					margin: 0 0 1rem;
					position: relative;
					padding-left: 1rem;
					.fw-medium;
					.cols(3);
					// margin-left: @grid-col-gutter;
					// padding-right: @grid-col-gutter;
					
					&:before {
						content: "";
						display: block;
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -8px;
						height: 16px;
						width: 16px;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url("../img/icons/white/back.svg");
						opacity: 0.8;
					}
				}
			}
		}
	}
	
	.toolbar__center {
		@media @vp-md-up {
			.cols(8);
			position: relative;
		}
		
		> .contents {
			padding-right: 0;
			padding-left: 0;
			margin-right: 0;
			border-right: 0;
			text-align: left;
			width: auto;
			
			> .title-page {
				text-align: left;
				width: 100%;
				padding-right: 0;
				margin-right: auto;
				margin-left: 0;
				.col-gutters;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				
				@media @vp-sm-down {
					.font-size-sectiontitle-sm;
					
					&:before { display: none; }
				}
				
				@media @vp-md-up {
					&:before {
						content: "";
						display: block;
						width: 1px;
						height: 100%;
						background: fade(@brand-blue-light, 35%);
						position: absolute;
						right: auto;
						left: -(percentage((1 / 8)));
						transform: translateX(@grid-col-gutter);
					}
				}
			}
		}
		
		@media @vp-md-up and @vp-lg-down {
			.cols(8.5);
			
			> .contents {
				> .title-page {
					&:before {
						left: -(percentage((0.5 / 8.5)));
					}
				}
			}
		}
	}
	
	.toolbar__right { display: none; }
	
	.site-body {
		> .width-limit {
			width: 100%;
			display: flex;
			
			@media @vp-sm-down {
				flex-direction: column;
			}
		}
	}
}