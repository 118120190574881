.menu-hamburger-initial(@include-patty: false) {
	@timing-function-ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
	@timing-function-ease-in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

	@patty-transition-delay: 250ms;

	.transition-timings(@include-patty) when not (@include-patty = false) {
		@burger-transition-delay: @patty-transition-delay;
		@bun-transition-duration: 500ms;
	}
	.transition-timings(@include-patty) when (default()) {
		@burger-transition-delay: 0ms;
		@bun-transition-duration: 500ms;
	}

	// Call the mixin
	.transition-timings(@include-patty);

	transition: transform 750ms @timing-function-ease-out-expo @burger-transition-delay;

	> .ingredient {
		&.-bun {
			> .bar {
				transition: transform @bun-transition-duration @timing-function-ease-in-out-back;
			}
		}

		&.-patty {
			> .bar {
				transition: transform 250ms cubic-bezier(0.175, 0.885, 0.320, 1.540) @patty-transition-delay;
			}
		}
	}
}

.menu-hamburger-open(@include-patty: false) {
	.transition-timings(@include-patty) when not (@include-patty = false) {
		@burger-transition-delay: 0ms;
		@bun-transition-duration: 375ms;
	}
	.transition-timings(@include-patty) when (default()) {
		@burger-transition-delay: 250ms;
		@bun-transition-duration: 375ms;
	}

	// Call the mixin
	.transition-timings(@include-patty);

	transform: rotate(90deg);
	transition-delay: @burger-transition-delay;

	> .ingredient {
		&.-patty {
			> .bar {
				transform: scaleX(0);
				transition-duration: (1s / 3);
				transition-timing-function: cubic-bezier(0.620, -0.540, 0.735, 0.045);
				transition-delay: 0s;
			}
		}

		&.-bun {
			@rotation: 45deg;

			> .bar {
				transition-duration: @bun-transition-duration;
			}

			&.-top {
				> .bar {
					transform: translateY(0) rotate(@rotation);
				}
			}

			&.-bottom {
				> .bar {
					transform: translateY(0) rotate(-@rotation);
				}
			}
		}
	}
}

.menu-hamburger(
	@target-width: 46px;
	@target-height: 40px;
	@burger-width: 100%;
	@burger-height: 2rem;
	@bun-thickness: 2px;
	@include-patty: false;

) {
	// Has patty
	.include-patty(@include-patty) when not (@include-patty = false) {
		> .ingredient {
			@bun-offset: (@burger-height / 2);

			&.-bun.-top {
				> .bar {
					transform: translateY(-@bun-offset);
				}
			}

			&.-bun.-bottom {
				> .bar {
					transform: translateY(@bun-offset);
				}
			}

			&.-patty {

			}
		}
	}

	// No patty
	.include-patty(@include-patty) when (default()) {
		> .ingredient {
			@bun-offset: (@burger-height / 2);

			&.-bun.-top {
				> .bar {
					transform: translateY(-@bun-offset);
				}
			}

			&.-bun.-bottom {
				> .bar {
					transform: translateY(@bun-offset);
				}
			}

			&.-patty {
				display: none;
			}
		}
	}

	position: relative;
	width: @target-width;
	height: @target-height;
	margin: 0;
	cursor: pointer;
	will-change: transform;

	> .ingredient {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		> .bar {
			position: absolute;
			display: block;
			margin-top: -(@bun-thickness / 2);
			margin-left: auto;
			margin-right: auto;
			top: 50%;
			left: 0;
			right: 0;
			width: @burger-width;
			height: @bun-thickness;
			background-color: currentColor;
		}
	}

	.menu-hamburger-initial(@include-patty);
	.include-patty(@include-patty);
}
