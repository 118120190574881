.body-text {
	.font-size-body;
	
	@media @vp-sm-down {
		.font-size-body-small;
	}
	
	* + ul,
	* + ol,
	* + p {
		margin-top: 1em;
	}
	
	strong {
		color: currentColor;
		.fw-bold;
	}
	
	em, i { font-style: italic; }

	blockquote {
		padding-left: 2em;
		border-left: 1px solid;
		
		p {
			font-size: inherit;
		}
	}
	
	h2 {
		.font-size-body-large;
		color: @brand-blue;
		.fw-bold;
		margin-bottom: 1em;
	}
	
	ul, ol {
		padding-left: 1.25em;
		list-style: none;
	}
	
	ul > li {
		list-style: disc;
	}

	ol > li {
		list-style: decimal;
	}
	
	> * {
		+ p, ul, ol {
			margin-top: 1em;
		}
		
		+ .button-link {
			margin-top: unit((20 / 12), em);
		}
	}
	
	p, li {
		margin-bottom: 0;
		
		a {
			color: @brand-blue;
			text-decoration: none;
		
			&:hover {
				color: currentColor;
			}
		}
	}
	
	&.-large {
		.font-size-body-large;
		
		* + ul,
		* + ol,
		* + p {
			margin-top: 0.5em;
		}
		
		ul {
			list-style: none;
			
			li {
				position: relative;
				
				&:before {
					content: "";
					display: block;
					width: 10px;
					height: 16px;
					// background: url("../img/chevron.svg") no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: -1em;
					top: unit((19 / 24), em); // Based on 36 point line height, 24 point type size
					transform: translateY(-50%);
				}
			}
		}
	}
	
	&.-small {
		.font-size-body-small;
	}

	&.-xsmall {
		.font-size-body-xsmall;
	}
	
	&.-metadata {
		.font-size-metadata;
		color: @brand-secondary;
	}
}