.page__content {
	> .site-body {
		> .width-limit {
			@media @vp-md-up {
				width: 100%;
			}
		}
	}
}

.site-body {
	overflow-y: auto;

	&.-split {
		overflow: visible;
	}
}