.project-nav {
	padding-top: 1rem;
	position: relative;
	
	@media @vp-sm-down {
		margin-bottom: 1rem;
		margin-left: -(@page-gutters-sm);
		margin-right: -(@page-gutters-sm);
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
		padding-bottom: 1rem;
		border-bottom: 1px solid @border;
	}
	
	@media @vp-md-up {
		padding-bottom: 1.5rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	> .banner-message {
		margin-bottom: 1rem;

		@media @vp-md-up {
			margin-top: -1rem;
			flex-grow: 1;
		}
	
		@media @vp-md-and-lg {
			margin-left: -(@page-gutters-md);
			margin-right: -(@page-gutters-md);
			padding-left: @page-gutters-md;
			padding-right: @page-gutters-md;
		}

		@media @vp-xl {
			margin-left: -(@page-gutters);
			margin-right: -(@page-gutters);
			padding-left: @page-gutters;
			padding-right: @page-gutters;
		}

		@media @vp-sm-down {
			margin-left: -(@page-gutters-sm);
			padding-left: @page-gutters-sm;
			width: auto;
			margin-right: 130px;
		}
	}
	
	> .option-menu {
		@media @vp-sm-down {
			.col-gutters-sm;
			margin-right: 120px;
			
			+ .option-menu { margin-top: 0.5rem; }
		}
		
		@media @vp-md-up {
			.col-gutters;
			
			+ .option-menu { margin-left: 0.5rem; }
		}
		
		+ .form-input {
			@media @vp-md-up {
				margin-left: auto;
				// .col-gutters;
			}
		}
	}

	> .button-group {
		@media @vp-sm-down {
			.col-gutters-sm;
			position: absolute;
			top: 1rem;
			right: @page-gutters-sm;
		}

		@media (max-width: 820px) {
			margin-left: auto;
			order: 2;
		}
		
		@media @vp-md-up {
			.col-gutters;
		}
		
		> .button-link {
			.extended {
				@media @vp-md-down {
					display: none;
				}
			}
		}
	}

	> .form-input.-text {
		> .field {
			padding-top: unit((7 / 16), em);
			padding-bottom: unit((7 / 16), em);
		}

		@media (max-width: 820px) {
			width: 100%;
			margin-top: 1rem;
			margin-bottom: 1rem;
			order: 3;
			.col-gutters-as-margin;
		}

		@media @vp-sm-down {
			.col-gutters-as-margin-sm;
		}
	}
}