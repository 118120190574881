.body-font() {
	.fw-regular;
}

.sans-serif() {
	.fw-regular;
}

.fw-regular() { font-family: "Work Sans Regular", @font-family-fallback; font-weight: normal; }
.fw-medium() { font-family: "Work Sans Medium", @font-family-fallback; font-weight: normal; }
.fw-bold() { font-family: "Work Sans Semibold", @font-family-fallback; font-weight: normal; }

.fw-regular-title() { font-family: "Montserrat Regular", @font-family-fallback; font-weight: normal; }
.fw-medium-title() { font-family: "Montserrat Medium", @font-family-fallback; font-weight: normal; }

.font-size-page-title() {
	.font-size(32);
	line-height: (40 / 32); 
	// letter-spacing: -(unit((0.5 / 32), em));
	.fw-regular-title;
	
	@media @vp-sm-down {
		.font-size(26);
	}
}

.font-size-sectiontitle() {
	.font-size(24);
	line-height: (32 / 24); 
	// letter-spacing: unit((0.5 / 32), em);
	.fw-regular-title;
	
	@media @vp-sm-down {
		.font-size(20);
	}
}

.font-size-sectiontitle-sm() {
	.font-size(18);
	line-height: (24 / 18); 
	// letter-spacing: unit((0.5 / 32), em);
	.fw-regular-title;
}

.font-size-smalltitle() {
	.font-size(18);
	line-height: (24 / 18); 
	// letter-spacing: unit((0.5 / 32), em);
	.fw-medium-title;
	
	@media @vp-sm-down {
		.font-size(16);
	}
}

.font-size-subtitle() {
	.font-size(16);
	line-height: (24 / 16); 
	.fw-medium-title;
}

.font-size-body-large() {
	.font-size(26);
	line-height: (30 / 24);
	.fw-regular;
}

.font-size-body() {
	.font-size(16);
	line-height: (22 / 16);
	.fw-regular;
}

.font-size-body-small() {
	.font-size(14);
	line-height: (18 / 14);
}

.font-size-body-xsmall() {
	.font-size(12);
	line-height: (20 / 12);
	// letter-spacing: unit((0.5 / 12), em);
}

.font-size-caption() {
	.font-size(14);
	line-height: (24 / 14);
	// letter-spacing: unit((0.5 / 14), em);
}

.font-size-metadata() {
	.font-size(12);
	line-height: (16 / 12);
}

.font-size-label() {
	.font-size(16);
	line-height: (24 / 16);
}

.font-size-label-sm() {
	.font-size(14);
	line-height: (24 / 14);
}

.font-size-field() {
	.font-size(16);
	line-height: (24 / 16);
	.fw-regular;
	// letter-spacing: unit((0.35 / 16), em);
}

.font-size-label-note() {
	.font-size(12);
	line-height: (16 / 12);
	.fw-regular;
}

.font-size-button() {
	.font-size(16);
	line-height: (22 / 16);
	.fw-medium;
}

.font-size-button-hero() {
	.font-size(@rem-base);
	line-height: (24 / 20);
}

.font-size-button-small() {
	.font-size(14);
	line-height: (24 / 14);
}

.font-size-button-xsmall() {
	.font-size(12);
	line-height: (20 / 12);
}

.font-size-button-micro() {
	.font-size(12);
	line-height: (16 / 12);
}

.font-size-ui() {
	.font-size(13);
	line-height: (22 / 13);
}

.spaced-uppercase(@size: 14) {
	text-transform: uppercase;
	.fw-bold;
	.font-size(@size);
	line-height: (16 / @size);
	letter-spacing: unit((1 / @size), em);
}