.media-element {
	margin: 0;

	> .image {
		display: block;
		
		img {
			display: block;
			// opacity: 0;
			// transition: opacity 0.2s @easeOutQuart;
	
			// &.lazyloaded {
			// 	opacity: 1;
			// }
		}
	
		> .aspect {
			position: relative;
			height: 0;
			width: 100%;
			background: @brand-blue-light;
			overflow: hidden;
	
			> img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: auto;
			}
		}
	}
	
	&.-reveal {
		opacity: 0;
		
		> .image {
			> .aspect {
				> .transition {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: white;
					display: none;
				}
			}
		}
		
		&.-revealed {
			animation: fade-in-up @easeOutQuart 1s forwards;
		}
	}
}