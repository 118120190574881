.page[status-bar-fill] {
	@media @vp-md-up {
		> .page__content {
			top: 0;
		}
	
		> .toolbar {
			padding-top: 0;
			box-sizing: border-box;
		
			&:not(.toolbar--transparent) + .page__background,
			&:not(.toolbar--cover-content) + .page__background + .page__content {
				top: @header-height;
			}
		}
	
		> .toolbar--material {
			&:not(.toolbar-transparent) + .page__background,
			&:not(.toolbar--cover-content) + .page__background + .page__content {
				top: @header-height;
			}
		}
	}
}

.toolbar {
	background-color: @brand-blue;
	color: white;
	overflow: visible;
	
	@media @vp-md-up and @vp-lg-down {
		padding-left: @page-gutters-md;
		padding-right: @page-gutters-md;
	}
	
	@media @vp-xl {
		padding-left: @page-gutters;
		padding-right: @page-gutters;
	}
	
	@media @vp-md-up {
		height: @header-height;
		display: flex;
		align-items: flex-end;
		
		&.toolbar--material {
			height: var(--toolbar-material-height);
			
			+ .page__background {
				top: @header-height;
			
				+ .page__content {
					top: @header-height;
				}
			}
		}
		
		+ .page__background {
			top: @header-height;
			
			+ .page__content {
				top: @header-height;
			}
		}
	}
	
	@media @vp-sm-down {
		// height: @header-height;
		display: flex;
		align-items: flex-end;
	}
}

.toolbar__left {
	display: none;
}

.toolbar__center {
	@media @vp-md-up {
		// padding-right: 1.5rem;
		// margin-right: 1.5rem;
		// padding-bottom: 1rem;
		position: relative;
		width: auto;
		height: @header-height;
		.cols(9);
		
		> .contents {
		    display: flex;
		    align-items: flex-end;
			height: @header-height;
			.col-gutters;
			
			> .title-page {
				padding-right: 1.5rem;
				margin-right: 1.5rem;
				padding-bottom: 1rem;
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				width: 200px;
				
				> .site-logo {
					height: 0;
					padding-top: percentage((43 / 255));
					width: 100%;
				}
			
				&:before {
					content: "";
					display: block;
					width: 1px;
					height: 100%;
					background: fade(@brand-blue-light, 35%);
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}
			
			> .title-section {
				line-height: (32 / 24);
				margin: 0 0 1rem;
			}
		}
	}
	
	@media @vp-sm-down {
		flex-grow: 1;
		display: flex;
		
		> .contents {
		    display: flex;
		    align-items: flex-end;
			// height: @header-height;
			.col-gutters-sm;
			
			> .title-page {
				margin-left: @grid-col-gutter;
				margin-right: 1.25rem;
				padding-bottom: unit((12 / @rem-base),rem);
				position: relative;
				width: 120px;
				
				> .site-logo {
					height: 0;
					padding-top: percentage((43 / 255));
					width: 100%;
				}
			
				&:before {
					content: "";
					display: block;
					width: 1px;
					height: 125%;
					background: fade(@brand-blue-light, 35%);
					position: absolute;
					right: -0.75rem;
					bottom: 0;
				}
			}
			
			> .title-section {
				line-height: (32 / 24);
				margin: 0 0 unit((10 / @rem-base),rem);
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				.font-size-sectiontitle-sm;
				text-align: left;
				max-width: calc(100vw - 204px);
			}
		}
	}
}

.toolbar__right {
	@media @vp-md-up {
		.cols(3);
		height: @header-height;
		
		> .contents {
		    display: flex;
		    align-items: flex-end;
			height: @header-height;
			.col-gutters;
			
			> .option-menu {
				line-height: (26 / 16);
				margin: 0 0 1rem;
				margin-left: auto;
				position: relative;
				padding-left: 1.75rem;
				.fw-medium;
				
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					left: 0;
					margin-top: -11px;
					height: 20px;
					width: 20px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url("../img/icons/white/avatar-large.svg");
					opacity: 0.8;
				}
				
				> .options { text-align: left; }
			}
		}
	}
	
	@media @vp-sm-down {
		display: flex;
		width: auto;
		
		> .contents {
		    display: flex;
		    align-items: flex-end;
			// height: @header-height;
			.col-gutters-sm;
			
			> .option-menu {
				line-height: (26 / 16);
				margin: 0;
				margin-left: auto;
				position: relative;
				// .fw-medium;
				height: 44px;
				
				> .title {
					height: 44px;
					
					> .selected {
						display: block;
						border-bottom: none;
						padding-right: 0;
						padding-left: (24px + @grid-col-gutter);
						height: 44px;
						.hide-text;
					}
				}
				
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 50%;
					left: @grid-col-gutter;
					margin-top: -11px;
					height: 20px;
					width: 20px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url("../img/icons/white/avatar-large.svg");
					opacity: 0.8;
				}
				
				> .options { text-align: left; }
			}
		}
	}
}

.site-header {
	background-color: @brand-blue;
	color: white;
	
	> .width-limit {
		@media @vp-md-up {
			> .contents {
				height: @header-height;
				display: flex;
				align-items: flex-end;
				.col-gutters;
				
				> .title-page {
					padding-right: 1.5rem;
					margin-right: 1.5rem;
					padding-bottom: 1rem;
					position: relative;
					
					&:before {
						content: "";
						display: block;
						width: 1px;
						height: 100%;
						background: fade(@brand-blue-light, 35%);
						position: absolute;
						right: 0;
						bottom: 0;
					}
				}
				
				> .title-section {
					line-height: (32 / 24);
					margin: 0 0 1rem;
				}

				> .account {
					line-height: (26 / 16);
					margin: 0 0 1rem;
					margin-left: auto;
					position: relative;
					padding-left: 1.75rem;
					.fw-medium;
					
					&:before {
						content: "";
						display: block;
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -11px;
						height: 20px;
						width: 20px;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url("../img/icons/white/avatar-large.svg");
						opacity: 0.8;
					}
				}
				
				> .button-link {
					margin-left: auto;
					margin-bottom: 0.5rem;
				}
			}
		}
		
		@media @vp-sm-down {
			height: @header-height-sm;
		}
		
		> .contents {
			> .title-page {
				text-align: left;
			}
		}
	}
}