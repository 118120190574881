.modal-content {
	background: @brand-blue;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	
	> .width-limit {
		padding-top: 2rem;
		padding-bottom: 2rem;
		
		> .body {
			background: white;
			border-radius: 8px;
			box-shadow: 0 2px 20px fade(@brand-blue-dark, 90%);
			max-width: 980px;
			margin-left: auto;
			margin-right: auto;
			
			> .modal-header {
				position: relative;
				
				> .contents {
					> .back {
						position: absolute;
						display: block;
						top: 1rem;
						right: -(percentage((1 / @grid-cols-total)));
						height: 32px;
						width: 32px;
						.hide-text();
						outline: none;
						opacity: 0.8;

						@media @vp-xs {
							right: 0;
						}
		
						&:hover {
							opacity: 1;
						}
		
						&:before,
						&:after {
							content: "";
							display: block;
							position: absolute;
							left: 50%;
							top: 50%;
							width: 24px;
							height: 2px;
							margin: 0;
							background-color: @body;
						}

						&:before { transform: translate(-50%, 0) rotate(-45deg); }
						&:after { transform: translate(-50%, 0) rotate(45deg);  }
					}
					
					> .title-page {
						color: @brand-blue;
						.col-gutters;
					}
				}
			}
			
			> .modal-header,
			> .modal-body {
				.cols(10);
				margin-left: auto;
				margin-right: auto;
				padding-top: 2rem;

				@media @vp-xs {
					width: auto;
					margin-left: 10px;
					margin-right: 10px;
				}
			}
			
			> .modal-body {
				padding-top: 1rem;
				padding-bottom: 3rem;
				
				.form-layout > .row + .row.-buttons { padding-top: 2rem; }
			}
		}
	}
	
	&.-annotation {
		> .width-limit {
			> .body {
				> .modal-header { z-index: 1; }
				> .modal-body {
					padding-bottom: 0;
					width: 100%;
				}
			}
		}
	}
}