.option-input(
	@hitarea: 44px,
	@size: 24px,
	@bg: white,
	@bg-hover: white,
	@bg-focus: white,
	@border: @brand-blue-light,
	@border-hover: @brand-blue,
	@border-focus: @brand-blue,
	@state-indicator-colour: @brand-blue, 
	@state-indicator-colour-active: @brand-blue, 
	@disabled-opacity: 0.35,
	@active-boxshadow: none,
	@text-gap: 8px
) {
	padding-left: (@size + @text-gap);
	position: relative;
	line-height: (24 / 16);
	
	> .radio,
	> .checkbox {
		position: absolute;
		left: 0;
		z-index: 0;
		opacity: 0;
		border: none;
		
		+ .substitute {
			pointer-events: none;
			display: block;
			width: @hitarea;
			height: @hitarea;
			position: absolute;
			left: (-((@hitarea - @size) / 2));
			top: unit((ceil(0.75 * @size) / @size), em);
			transform: translateY(-50%);
			// outline: 1px solid red;
			
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
			}
			
			&:before {
				padding: 0;
				margin: 0;
				width: @size;
				height: @size;
				background-color: @bg;
				border: 2px solid @border;
				box-sizing: border-box;
				transform: translate(-50%, -50%);
			}
			
			&:after {
				display: none;
			}
		}
		
		&:checked + .substitute,
		&:active + .substitute {
			&:after { display: block; }
		}
		
		&:checked + .substitute {
			&:before {
				border-color: @border-focus;
			}
		}
		
		&:focus {
			+ .substitute {
				// background: @bg-focus;
				&:before {
					border-color: @border-focus;
				}
			}
		
			&:checked + .substitute {
				&:after {
					// border-color: @border-focus;
					// background: @state-indicator-colour-active;
				}
			}
		
			&:disabled,
			&:disabled:checked {
				+ .substitute {
					&:before {
						background-color: @bg;
						border: 1px solid @border;
					}

					// &:after {
					// 	border-color: @border;
					// 	background: @bg;
					// }
				}
			}
		}
		
		&:active {
			&:checked + .substitute {
				&:after {
					// border-color: @border-focus;
					// background: @state-indicator-colour-active;
				}
			}
		}
		
		&:disabled {
			+ .substitute {
				&:before {
					opacity: @disabled-opacity;
				}
				
				&:after {
					border-color: @state-indicator-colour;
				}
			}
			
			&:active + .substitute {
				&:after {
					border-color: @state-indicator-colour;
				}
			}
			
			&:checked + .substitute {
				&:after {
					border-color: @state-indicator-colour;
				}
			}
		}
	}
	
	> .checkbox {
		+ .substitute {
			&:before {
				border-radius: 5px;
			}
			
			&:after {
				width: 13px;
				height: 7px;
				border-bottom: 3px solid @state-indicator-colour;
				border-left: 3px solid @state-indicator-colour;
				transform: translate(-6px, -5px) rotate(-45deg);
			}
		}
	}
	
	> .radio {
		+ .substitute {
			&:before {
				border-radius: 50%;
			}
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				transform-origin: 50% 50%;
				display: none;
				width: 10px;
				height: 10px;
				transform: translate(-50%, -50%);
				background: @state-indicator-colour;
				border-radius: 50%;
			}
		}
		
		&:disabled {
			+ .substitute {
				&:after {
					background: @state-indicator-colour;
				}
			}
			
			&:active + .substitute {
				&:after {
					background: @state-indicator-colour;
				}
			}
			
			&:checked + .substitute {
				&:after {
					background: @state-indicator-colour-active;
				}
			}
		}
	}
	
	&:hover {
		> .radio,
		> .checkbox {
			+ .substitute {
				// background: @bg-hover;
				&:before {
					border-color: @border-hover;
				}
			}
		
			&:focus,
			&:active,
			&:checked {
				+ .substitute {
				}
			}
		}
	}
}