.site-grid {
	display: flex;
	z-index: 9999;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	// width: 100%; // IE10
	pointer-events: none;
	opacity: 0.05;
	padding-left: @page-gutters;
	padding-right: @page-gutters;
	box-sizing: content-box !important;
	
	@media @vp-md-up and @vp-lg-down {
		padding-left: @page-gutters-md;
		padding-right: @page-gutters-md;
	}
	
	@media @vp-sm-down {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
	}
	
	.column {
		flex: 1;
		background-color: #f00;
		margin-left: @grid-col-gutter;
		margin-right: @grid-col-gutter;
		
		@media @vp-sm-down {
			margin-left: @grid-col-gutter-sm;
			margin-right: @grid-col-gutter-sm;
			
			// &:nth-child(n + 7) { display: none; }
		}
	}
}