.form-input {
	>  .field,
	>  .select {
		box-sizing: border-box;
		.font-size-body;
		font-family: inherit;
		color: @brand-blue;
	}
	
	&.-text,
	&.-textarea {
		> .field {
			appearance: none;
			width: 100%;
			border: 2px solid @brand-blue-light;
			border-radius: 3px;
			padding: unit((9 / 16), em) unit((8 / 16), em);
			color: @brand-blue;
			// box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.25);
			
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: @body-secondary;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: @body-secondary;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: @body-secondary;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: @body-secondary;
			}

			&.error {
				background-color: red;
				color: white !important;
			}
			
			&[disabled] {
				color: mix(@brand-blue, @brand-blue-light, 50%);
				border-color: mix(@brand-blue-light, white, 75%);
			}

			&:focus {
				outline: none;
				border-color: @brand-blue
			}
		}

		&.-search {
			position: relative;

			> .icon {
				width: 20px;
				height: 20px;
				position: absolute;
				top: 50%;
				right: 8px;
				margin-top: -10px;

				&:before,
				&:after {
					content: "";
					display: block;
					position: absolute;
				}

				&:before {
					width: 16px;
					height: 16px;
					border: 2px solid @brand-blue;
					top: 0;
					left: 0;
					border-radius: 8px;
				}

				&:after {
					width: 10px;
					height: 2px;
					background: @brand-blue;
					top: 16px;
					left: 16px;
					transform: translate(-50%, -50%) rotate(45deg);
				}
			}

			> .field {
				padding-right: unit((24 / 16), em);
			}
		}
	}
	
	&.-textarea {
		> .field {
			min-height: unit(((18 + (24 * 4)) / 16), em);
		}
	}
	
	&.-select {
		> .select {
		    -webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			// margin: 0;
			border-radius: 3px;
			border: 2px solid;
			padding: unit((9 / 16), em) unit((40 / 16), em) unit((9 / 16), em) unit((8 / 16), em);
			width: 100%;
			cursor: pointer;
			border-color: @brand-blue-light;
			background-color: #fff;
			background-image: url("../img/select-arrow.svg");
			background-size: 26px 44px;
			background-position: 100% 50%;
			background-repeat: no-repeat;
			// box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
			
			&:-ms-expand { display: none; }
			
			&.-withother {
				width: 75%;
				
				+ .field {
					width: 25%;
					border: 2px solid @brand-blue-light;
					border-radius: 3px;
					padding: unit((9 / 16), em) unit((8 / 16), em);
					color: @brand-blue;
					margin-left: 1rem;
				}
			}

			&:focus {
				outline: none;
				border-color: @brand-blue
			}
		}
		
		> .combobox {
			> .combobox__control {
				border-radius: 3px;
				border: 2px solid;
				border-color: @brand-blue-light;
				padding: 0;
				
				.combobox__value-container {
					padding: 0;
				}
				
				.combobox__placeholder,
				.combobox__input,
				.combobox__single-value {
					padding: unit((9 / 16), em) unit((8 / 16), em);
					margin: 0;
				}
				
				.combobox__placeholder + div,
				.combobox__single-value + div {
					// No class for this one :(
					margin: 0;
					padding: 0;
				}
				
				.combobox__indicator-separator { display: none; }
				
				.combobox__dropdown-indicator {
					background-image: url("../img/select-arrow.svg");
					background-size: 26px 44px;
					background-position: 100% 50%;
					background-repeat: no-repeat;
					width: unit((32 / 16), em);
					
					> svg { display: none; }
				}
				
				.combobox__clear-indicator {
					background-image: url("../img/icons/blue/cancel.svg");
					background-position: 50% 50%;
					background-repeat: no-repeat;
					width: unit((32 / 16), em);
					
					> svg { display: none; }
				}
			}
			
			> .combobox__menu {
				z-index: 2;
			}
		}
	}
	
	&.-toggle {
		padding-top: unit((5 / 16), em);
		padding-bottom: unit((5 / 16), em);
	}
	
	> .value {
		.fw-medium;
		margin: 0;
	}

	> .warning {
		color: @brand-secondary;
	}
	
	> .option-inputs + .form-input { padding-top: 0.5rem; }
	
	> .form-input {
		display: flex;
		
		> .field {
			margin-right: @grid-col-gutter;
		}
		
		>  .units {
			display: block;
			align-self: center;
			.col-gutters;
			.font-size-label;
			color: @body-secondary;
			margin: 0;
			max-height: 44px;
		}
	}
}