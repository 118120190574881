.signin-form {
	display: block;
	background: @brand-blue;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
	> .form-layout {
		.cols(4);
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 2rem;
		
		@media @vp-xl {
			.cols(3);
		}

		@media @vp-md {
			.cols(6);
		}

		@media @vp-sm {
			.cols(8);
		}

		@media @vp-xs {
			.cols(10);
		}
		
		> .site-logo {
			.page__content & {
				margin: 0 auto;
				margin-bottom: 3rem;
			}
		}
		
		.title-sub {
			color: white;
			text-align: center;
			background: none;
			padding-top: 0;
			padding-bottom: 0;
			border: none;
		}
	}
	
	.form-field {
		> .form-input { width: 100%; }
		> .button-group {
			width: 100%;
			justify-content: center;
		}
	}
}