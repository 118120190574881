.site-menu-hamburgers {
	.menuburger {
		touch-action: manipulation;

		> .ingredient {
			> .bar {
				transition: background-color 0.2s ease-in-out;
			}
		}

		&:hover {
			> .ingredient {
				> .bar {
					background-color: @brand-blue-dark;
				}
			}
		}

		.menu-hamburger(
			@target-width: @site-menu-hamburger-dimension-width;
			@target-height: @site-menu-hamburger-dimension-height;
			@burger-width: 18px;
			@burger-height: 11px;
			@bun-thickness: 1px;
			@include-patty: true;
		)
	}
}

.mobile-nav.menu-open {
	.site-menu-hamburgers {
		.menuburger {
			.menu-hamburger-open();
		}
	}
}