.site-logo {
	width: 225px;
	height: 43px;
	.hide-text;
	margin: 0;
	background-image: url("../img/maqasa.png");
	background-position: 0 0;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	
	&.-reversed {
		background-image: url("../img/maqasa-blue.png");
	}
}
