.annotation-toolbar {
	background: @brand-grey;
	color: white;
	padding: 0.5rem 1rem;
	border-bottom-right-radius: 7px;
	border-bottom-left-radius: 7px;
	text-align: center;
	
	> .group {
		display: inline-block;
		border-radius: 4px;
	}
	
	> .option,
	> .group > .option {
		display: inline-block;
		width: 44px;
		height: 44px;
		position: relative;
		overflow: hidden;
		.hide-text;
		// border: 1px solid fade(white, 25%);
		background: mix(@brand-black, @brand-grey, 25%);
		
		&:after {
			content: "";
			display: block;
			width: 24px;
			height: 24px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 0 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		
		&.-select:after { background-image: url("../img/icons/toolbar/select.svg"); }
		&.-draw:after { background-image: url("../img/icons/toolbar/draw.svg"); }
		&.-undo:after { background-image: url("../img/icons/toolbar/undo.svg"); }
		&.-redo:after { background-image: url("../img/icons/toolbar/redo.svg"); }
		&.-text:after { background-image: url("../img/icons/toolbar/text.svg"); }
		&.-delete:after { background-image: url("../img/icons/toolbar/delete.svg"); }

		&.-colour {
			&:after {
				border-radius: 12px;
			}
			
			&.-red:after { background: red; }
			&.-black:after { background: black; }
			&.-white:after { background: white; }
		}
		
		&.-disabled {
			&:after { opacity: 0.25; }
		}
		
		&.-undo:active:not(.-disabled),
		&.-redo:active:not(.-disabled),
		&.-text:active:not(.-disabled),
		&.-delete:active:not(.-disabled),
		&.-selected {
			&:before {
				content: "";
				display: block;
				width: 34px;
				height: 34px;
				background: white;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 3px;
			}
		}
		
		&.-selected {
			&.-select:after { background-image: url("../img/icons/toolbar/select-active.svg"); }
			&.-draw:after { background-image: url("../img/icons/toolbar/draw-active.svg"); }
			&.-colour {
				&.-white:after {
					border: 1px solid @brand-grey;
				}
			}
		}
		
		&.-undo:active:not(.-disabled) {
			&:after { background-image: url("../img/icons/toolbar/undo-active.svg"); }
		}
		&.-redo:active:not(.-disabled) {
			&:after { background-image: url("../img/icons/toolbar/redo-active.svg"); }
		}
		&.-text:active:not(.-disabled) {
			&:after { background-image: url("../img/icons/toolbar/text-active.svg"); }
		}
		&.-delete:active:not(.-disabled) {
			&:after { background-image: url("../img/icons/toolbar/delete-active.svg"); }
		}
	}
	
	> .option,
	> .group {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	
	> .option {
		border-radius: 4px;
	}
	
	> .group {
		> .option {
			border-right: none;
			
			&:first-child {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			
			&:last-child {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
				// border-right: 1px solid fade(white, 25%);
			}
		}
	}
}