._clearfix {
	.clearfix();
}

._no-boxshadow { box-shadow: none !important; }

._nowrap { white-space: nowrap; }

._hide-text {
	.hide-text();
}

._sr-only {
	.sr-only();
}

._sr-only-focusable {
	.sr-only-focusable();
}

._sm-down-only {
	@media @vp-md-up { display: none; }
}

._md-down-only {
	@media @vp-lg-up { display: none; }
}

._hide-mobile {
	@media @vp-sm-down { display: none; }
}

._mobile-only {
	@media @vp-md-up { display: none; }
}

// ._reveal {
// 	&._fadeup {
// 		opacity: 0;
//
// 		&.-revealed {
// 			animation: fade-in-up @easeOutQuart 0.75s forwards;
// 			animation-delay: 0.2s;
// 		}
// 	}
//
// 	&._fadeleft {
// 		opacity: 0;
//
// 		&.-revealed {
// 			animation: fade-slide-left @easeInOutQuint 0.75s forwards;
// 			animation-delay: 0.2s;
// 		}
// 	}
//
// 	&._faderight {
// 		opacity: 0;
//
// 		&.-revealed {
// 			animation: fade-slide-right @easeInOutQuint 0.75s forwards;
// 			animation-delay: 0.2s;
// 		}
// 	}
// }