.button-size-default() {
	.font-size-button;
	padding: unit((9 / 16), em) 1.5em unit((9 / 16), em);
}

.button-size-text() {
	.font-size-button;
	padding: unit((9 / 16), em) 1.5em unit((9 / 16), em);
}

.button-size-hero() {
	.font-size-button-hero;
	padding: unit((16 / @rem-base), em) 2em unit((16 / @rem-base), em);
}

.button-size-small() {
	.font-size-button-small;
	padding: unit((4 / 14), em) 1em unit((6 / 14), em);
}

.button-size-xsmall() {
	.font-size-button-xsmall;
	padding: unit((5 / 12), em) 1.5em unit((6 / 12), em);
}

.button-size-micro() {
	.font-size-button-micro;
	padding: unit((3 / 12), em) 1em unit((4 / 12), em);
}