.edit-tile {
	background: fade(@brand-blue-light, 97%);
	
	> .options {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		
		> .option {
			display: inline-block;
			cursor: pointer;
			background: transparent;
			appearance: none;
			.font-size-body;
			.fw-medium;
			border: none;
			padding: 0.5rem;
			padding-left: 1.5rem;
			position: relative;
			color: @brand-blue;
			width: 94px;
			text-align: left;
			
			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -9px;
				width: 16px;
				height: 16px;
				background-image: url("../img/icons/blue/delete.svg");
				background-position: 0 0;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				opacity: 0.8;
				margin-right: 0.5rem;
			}
			
			&.-delete {
				&:before { background-image: url("../img/icons/blue/delete.svg"); }
			}
			
			&.-archive {
				&:before { background-image: url("../img/icons/blue/archive.svg"); }
			}
		}
	}
}