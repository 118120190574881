.border(top) {
	&:before {
		content: "";
		display: block;
		height: 1px;
		position: absolute;
		left: @grid-col-gutter;
		right: @grid-col-gutter;
		top: 0;
		background: @brand-blue-light;
	}
}

.border(bottom) {
	&:after {
		content: "";
		display: block;
		height: 1px;
		position: absolute;
		left: @grid-col-gutter;
		right: @grid-col-gutter;
		bottom: 0;
		background: @brand-blue-light;
	}
}