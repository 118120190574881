.form-layout {
	position: relative;
	
	> .title-section {
		.col-gutters;
	}
	
	> .title-sub {
		.col-gutters;
		padding-top: 0.5rem;
		padding-bottom: 0.25rem;
		margin-bottom: 1rem;
		color: @brand-blue;
		// background: mix(@brand-blue-light, white, 35%);
		background: @brand-blue-xlight;
		.fw-medium-title;
		border-bottom: 2px solid @brand-blue-light;
	}
	
	> * + .title-sub { margin-top: 3rem; }
	> * + .title-section { margin-top: 3rem; }
	> * + .row.-subsection {
		margin-top: 3rem;
		
		@media @vp-md-down {
			margin-top: 2rem;
		}
	}
	
	> .copy-link {
		position: absolute;
		top: 0.5rem;
		right: 0;
		.font-size-body-small;
		padding-right: @grid-col-gutter;
		
		+ .title-section {
			margin-top: 0;
		}
		
		&.-from {
			+ .title-section {
				margin-right: 120px;
			}
		}
		
		&.-to {
			+ .title-section {
				margin-right: 100px;
			}
		}
		
		&:before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			position: relative;
			top: -1px;
			height: 16px;
			width: 16px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-right: 0.5rem;
			background-image: url("../img/icons/blue/copy-to.svg");
			opacity: 0.8;
		}
	}
	
	> .row {
		+ .row,
		+ .extra {
			padding-top: 1rem;
		}

		+ .extra {
			margin-top: 1rem;
		}
		
		&.-buttons {
			&.-split {
				display: flex;
				
				> .button-group + .button-group {
					margin-left: auto;
				}
			}
		}
		
		> .button-group {
			.col-gutters;
		}

		&.-addextra {
			margin-top: 1rem;
			margin-bottom: 1rem;

			> .button-group {
				flex-direction: row;
			}
		}

		+ .title-small {
			margin-top: 2rem;
		}

		> .option-inputs {
			.col-gutters;
		}
	}

	> .extra {
		border-bottom: 1px solid @brand-blue-light;
		padding-bottom: 1rem;
		margin-top: 1rem;
		position: relative;

		.button-link {
			position: absolute;
			bottom: 1rem;
			left: @grid-col-gutter;
			background: @brand-blue-light;
			color: @brand-blue;

			@media @vp-md-down {
				bottom: auto;
				top: 1rem;
				left: auto;
				right: @grid-col-gutter;
			}

			&:hover {
				background: @brand-secondary;
				color: white;
			}
		}
	}

	> .title-small {
		.col-gutters;
	}

	> .warning {
		padding-left: 8px;
		color: @brand-red;
		margin-top: 1em;
		margin-bottom: 2em;
		text-align: left;
		.font-size-body-small;
	}
}
