.side-nav {
	margin-right: -(@grid-col-gutter * 2);

	@media @vp-md-up {
		margin-right: -(@grid-col-gutter);
	}
	
	> .navitems {
		list-style: none;
		padding-left: 0;
		margin: 0;
		
		> .navitem {
			cursor: pointer;
			margin: 0;
			.font-size-subtitle;
			border-bottom: 1px solid fade(@brand-blue, 15%);

			@media @vp-sm-down {
				border: none;
			}
			
			> .link {
				display: block;
				padding-top: 0.75rem;
				padding-bottom: 0.75rem;
				padding-right: 2rem;
				color: @body-secondary;
			}
			
			&.-subsection {
				> .link {
					.spaced-uppercase;
					color: @body;
				}
			}
			
			> .subnavitems {
				list-style: none;
				padding-left: 1.5rem;
				margin: 0;
				
				> .navitem {
					margin: 0;
					.font-size-subtitle;
					border-top: 1px solid fade(@brand-blue, 15%);

					@media @vp-sm-down {
						border: none;
					}
					
					> .link {
						display: block;
						padding-top: 0.75rem;
						padding-bottom: 0.75rem;
						padding-right: 2rem;
						color: @body-secondary;

						@media @vp-sm-down {
							padding-top: 0.5rem;
							padding-bottom: 0.5rem;
						}
					}
					
					&.-selected {
						position: relative;
				
						&:after {
							content: "";
							display: block;
							position: absolute;
							right: (@grid-col-gutter * 2);
							top: 50%;
							margin-top: -8px;
							width: 16px;
							height: 16px;
							background-repeat: no-repeat;
							background-size: 100% 100%;
							background-image: url("../img/icons/blue/arrow.svg");
							opacity: 0.8;
						}
				
						> .link {
							color: @body;
						}
					}
				}
			}
			
			&.-selected {
				position: relative;
				
				&:after {
					content: "";
					display: block;
					position: absolute;
					right: (@grid-col-gutter * 2);
					top: 50%;
					margin-top: -8px;
					width: 16px;
					height: 16px;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					background-image: url("../img/icons/blue/arrow.svg");
					opacity: 0.8;
				}
				
				> .link {
					color: @body;
				}
			}
		}
	}
}